import { useState, useEffect } from "react";

const useGooglePlacePrice = ({ placeId }) => {
  const [priceLevel, setPriceLevel] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(
        {
          placeId: placeId,
          fields: ["price_level"],
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPriceLevel(place?.price_level);
          } else {
            console.error("Error fetching place details:", status);
          }
        }
      );
    };

    if (placeId && window.google) {
      fetchData();
    }
  }, [placeId]);

  return {
    priceLevel,
  };
};

export default useGooglePlacePrice;

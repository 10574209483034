import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUserAuthenticated: false
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    authenticateUser: (state, { payload }) => {
      state.isUserAuthenticated = payload.isUserAuthenticated;
    },
  },
});

export const getAuthStatus = (state) => state?.authReducer?.isUserAuthenticated;

const { actions, reducer } = authSlice;
export const {
  authenticateUser,
} = actions;
export default reducer;


import moment from "moment";

export const getPriceSymbol = (priceLevel) => {
  switch (priceLevel) {
    case 1:
      return "$";
    case 2:
      return "$$";
    case 3:
      return "$$$";
    case 4:
      return "$$$$";
    default:
      return "N/A"; // You can set a default symbol or message for other price levels
  }
};

export function secondsToTime(secs) {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
}

export const utcToLocalDate = (date, format) => {
  var localTime = moment.utc(date).toDate();

  if (!moment(date).isValid()) return "";

  if (format == null) {
    return moment(localTime).toDate();
  } else {
    localTime = moment(localTime).format(format);
    return localTime;
  }
};

// Convert the input time to a formatted time string
export const formattedTime = (time) => {
  const momentTime = moment(time, "HHmm");
  return momentTime.format("h:mm A");
};

export const getDatesBetween = (dateStart, dateEnd) => {
  const dateArray = [];
  const currentDate = new Date(dateStart);
  const endDateObj = new Date(dateEnd);

  while (currentDate <= endDateObj) {
    const formattedDate = currentDate.toISOString().slice(0, 10);
    dateArray.push(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
};

export const getAlternativeGoogleImage = async (placeId) => {
  const alternativeImage = new Promise((resolve,reject)=>{
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  
    service.getDetails(
      {
        placeId: placeId,
        fields: ["photos"],
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          resolve(place?.photos?.[0]?.getUrl());
        } else {
          console.error("Error fetching place details:", status);
          reject(null)
        }
      }
    );
  })
  return alternativeImage
};

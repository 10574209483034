import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import config from "config.js";
import "../Trip.css";
import TripList from "../../assets/Icons/trip-list.svg";
import Avatar1 from "../../assets/ListAvatar1.svg";
import Avatar2 from "../../assets/ListAvatar2.svg";
import { Divider, Flex, Tabs, Affix, Skeleton } from "antd";
import ReccosListCard from "./ReccosListCard";
import AuthModal from "common/Components/AuthModal";
import { useSelector } from "react-redux";
import { getAuthStatus } from "../../redux/slices/authSlice";
import TripListImage from "../../assets/TripListImage.png";
import TopNav from "common/Components/TopNav";
import Details from "common/Components/Details";
import DownloadApp from "common/Components/DownloadApp";
import Globe from "../../assets/Icons/Globe.svg";
import Lock from "../../assets/Icons/black-lock.svg";
import ScreenContextProvider from "common/utils/ScreenContextProvider";

export default function TripDetails() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getAuthStatus);
  const [showPopup, setShowPopup] = useState(false);
  const [listData, setListData] = useState(null);
  const { id } = useParams();
  const { baseUrl, screenMapping } = config;
  const [contextValue, setContextValue] = useState(null);

  useEffect(() => {
    // Event listener to capture all click events on the landing page container
    const handleContainerClick = (event) => {
      const clickedElement = event.target;
      
      // Check if the clicked element is a button
      if (
        (clickedElement.tagName === "BUTTON" ||
          clickedElement.tagName === "IMG" ||
          clickedElement.role === "tab" ||
          clickedElement.tagName === "INPUT" ||
          clickedElement.innerHTML === 'follow') &&
        !isAuthenticated
      ) {
        // Display the login/signup popup
        setShowPopup(true);
      }
    };

    // Add the event listener to the landing page container
    document
      .getElementById("top-nav")
      .addEventListener("click", handleContainerClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document
        .getElementById("top-nav")
        .removeEventListener("click", handleContainerClick);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    async function getList(listId) {
      fetch(`${baseUrl}/api/v1/webapp/get_detail?id=${listId}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("api list data -----", data?.successData);
          setListData(data?.successData);
        });
    }
    setContextValue({ screenType: screenMapping.FeedListView, id: id });
    getList(id);
  }, [id, baseUrl, screenMapping]);

  const items = [
    {
      key: "1",
      label: `all`,
      children: (
        <ReccosListCard
          extraStyles={"w-full"}
          navigate={navigate}
          reccos={listData?.recommendation_events}
        />
      ),
      centered: true,
    },
    {
      key: "2",
      label: `eat`,
      centered: true,
    },
    {
      key: "3",
      label: `drink`,
      centered: true,
    },
    {
      key: "4",
      label: `stay`,
      centered: true,
    },
    {
      key: "5",
      label: `do`,
      centered: true,
    },
  ];

  return (
    <>
      <div
        id="top-nav"
        className="md:w-[24.375rem] lg:w-[33.625rem] overflow-y-hidden"
      >
        <TopNav image={TripListImage} />
        {listData?.pic ? (
          <Flex
            align="center"
            className="trip-image h-[16rem] w-full"
            style={{
              background: `url(${listData?.pic}) center center / cover no-repeat`,
            }}
          >{` `}</Flex>
        ) : (
          <Skeleton.Image
            className="trip-image h-[16rem] w-full"
            active={true}
          />
        )}
        <div id="scrollable-content">
          <Flex
            vertical
            // gap={38}
            className="relative z-[1] top-[-1.5rem] rounded-t-[1.875rem] bg-offWhite"
          >
            <div className="pt-5 px-5">
              <Details
                icon={
                  <div className={`bg-blue-01 p-1 rounded-sm`}>
                    <img alt="Trip List Icon" src={TripList} />
                  </div>
                }
                privacyIcon={listData?.privacy === "public" ? Globe : Lock}
                collaboraters={listData?.collaboraters}
                avatar1={Avatar1}
                avatar2={Avatar2}
                title={listData?.title}
                location={listData?.place?.address}
                description={listData?.description}
                type={`${listData?.privacy} ${listData?.type}`}
                tags={listData?.user_tags}
              />
              <Divider className="border-gray-04 mt-[25px] mb-5" />
            </div>
            <Tabs
              className="trips-tab"
              defaultActiveKey="1"
              activeKey="1"
              items={items}
            />
          </Flex>
        </div>
        {/* <div
          className="h-[11.25rem] z-[1] relative ![background:linear-gradient(180deg,rgb(255,255,255,0)_0%,rgb(255,255,255,1)_100%]"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
          }}
        ></div> */}
        <Affix offsetBottom={-800} className="z-10 relative">
          {contextValue && (
            <ScreenContextProvider
              id={contextValue.id}
              screenType={contextValue.screenType}
            >
              <DownloadApp
                horizontalPadding={"px-[2.375rem] lg:px-[6.375rem]"}
              />
            </ScreenContextProvider>
          )}
        </Affix>
      </div>
      {contextValue && (
        <ScreenContextProvider
          id={contextValue.id}
          screenType={contextValue.screenType}
        >
          <AuthModal isModalOpen={showPopup} setIsModalOpen={setShowPopup} />
        </ScreenContextProvider>
      )}
    </>
  );
}

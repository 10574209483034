import React from "react";
import { Input, Flex } from "antd";

const FilterList = ({ placeholdertext, inputIcon, icons, extraStyles }) => {
  return (
    <>
      <Flex justify="space-between" gap={20}>
        <Input
          className={`border-0 border-b-[0.094rem] rounded-none bg-inherit lg:w-[19.063rem] border-gray-05 text-[0.916rem]/[1.547rem] ${extraStyles}`}
          size="large"
          placeholder={placeholdertext}
          prefix={inputIcon}
        />
        <Flex gap={10}>
          {icons && icons.map((icon) => <img src={icon} alt={icon} />)}
        </Flex>
      </Flex>
    </>
  );
};

export default FilterList;

import React from "react";
import { Row, Flex, Card, Image } from "antd";
import PlaneGreen from "../../assets/Icons/PlaneGreen.svg";
import Calendar from "../../assets/Icons/calendar-grey.svg";
import Settings from "../../assets/Icons/settings.svg";
import Glass from "../../assets/Icons/glass-grey.svg";
import Bed from "../../assets/Icons/bed-grey.svg";
import Food from "../../assets/Icons/food-grey.svg";
import AddTo from "../../assets/Icons/add-to-green.svg";
import moment from "moment";

export default function TripItinerary({ recommendations, showEventsByDay, navigate }) {
  return (
    <>
      <Row className="px-1" justify="space-between">
        <Flex className="">
          <Flex gap={3} className="font-extrabold text-[14px] p-[7px]">
            <img alt="Plane Icon Green" src={PlaneGreen} />
            full trip
          </Flex>
          <Flex
            gap={3}
            className="p-[7px] text-gray-03 font-extrabold text-[14px]"
          >
            <img alt="Calendar" src={Calendar} />
            day-to-day
          </Flex>
        </Flex>
        <img alt="Settings" src={Settings} />
      </Row>
      <Row>
        {recommendations &&
          Object.keys(recommendations).map((date, index) => (
            <Flex
              id="trip-itinerary"
              key={`itinerary-${index}`}
              vertical
              gap={16}
              className={`w-full ${date === "0" ? "pt-[15px]" : ""}`}
            >
              {date !== "0" && (
                <div className="py-[15px] font-bold text-base border-b border-gray-02">
                  {showEventsByDay
                    ? `day ${date}`
                    : moment(new Date(date)).format("MMMM Do")}
                </div>
              )}
              {recommendations[date]?.map((place,index1) => (
                <Card
                  key={`itinerary-card-${index1}`}
                  style={{ height: "auto" }}
                  className="lg:mx-[68.5px] trip-list shadow-[0_1px_4px_0px_rgba(0,0,0,0.25)] h-[105px]"
                  onClick={()=>navigate(`/recco/${place?.recommendation?.place_id}`)}
                >
                  <Flex style={{ flex: "auto" }}>
                    <div
                      className="h-[105px] w-[125px] rounded-s-lg"
                      style={{
                        background: `url(${place.recommendation.pic}) center center / cover no-repeat`,
                        height: "auto",
                      }}
                    ></div>
                    <Flex
                      justify="space-between"
                      className="p-2.5"
                      vertical
                      flex={"auto"}
                    >
                      <Flex gap={5} vertical>
                        <div className="text-base font-bold">
                          {place.recommendation.place.name}
                        </div>
                        <Flex gap={10}>
                          <Image
                            preview={false}
                            className="p-[3px] border rounded-sm border-dark-grey"
                            src={Glass}
                          />
                          <Image
                            preview={false}
                            className="py-[3px] px-[1px] border rounded-sm border-dark-grey"
                            src={Bed}
                          />
                          <Image
                            preview={false}
                            className="p-[3px] border rounded-sm border-dark-grey"
                            src={Food}
                          />
                        </Flex>
                      </Flex>
                      <Flex justify="space-between" align="end">
                        <div className="p-[3px] h-fit bg-light-gray text-white font-[510] rounded-[3px] text-[10px]/[10px]">
                          {`${
                            place.recommendation.place.recommendations_count
                          } recco${
                            place.recommendation.place.recommendations_count > 1
                              ? "s"
                              : ""
                          }`}
                        </div>
                        <Image preview={false} src={AddTo} />
                      </Flex>
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Flex>
          ))}
      </Row>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Plane from "../../assets/Icons/Plane.svg";
import Avatar1 from "../../assets/Avatar1.png";
import Avatar2 from "../../assets/Avatar2.png";
import Calendar from "../../assets/Icons/Calendar.svg";
import Media from "../../assets/Icons/Media.svg";
import { Flex, Tabs, Affix, Skeleton } from "antd";
import TripItinerary from "./TripItinerary";
import AuthModal from "../../common/Components/AuthModal";
import "../Trip.css";
import { useSelector } from "react-redux";
import { getAuthStatus } from "../../redux/slices/authSlice";
import TopNav from "../../common/Components/TopNav";
import Details from "../../common/Components/Details";
import DownloadApp from "common/Components/DownloadApp";
import { useParams } from "react-router-dom";
import Globe from "../../assets/Icons/Globe.svg";
import Lock from "../../assets/Icons/black-lock.svg";
import config from "config.js";
import ScreenContextProvider from "common/utils/ScreenContextProvider";

export default function TripDetails(props) {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getAuthStatus);
  const [showPopup, setShowPopup] = useState(false);
  const [tripData, setTripData] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [eventsByDay, setEventsByDay] = useState(false);
  const { id } = useParams();
  const { baseUrl, screenMapping } = config;
  const [contextValue, setContextValue] = useState(null);

  useEffect(() => {
    async function getTrip(tripId) {
      fetch(`${baseUrl}/api/v1/webapp/get_detail?id=${tripId}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("trip data details", data);
          setTripData(data?.successData);
          if (data?.successData?.days_count > 0) {
            getReccomendationsByDay(
              data?.successData?.recommendation_events,
              data?.successData?.days_count
            );
            setEventsByDay(true);
          } else getReccomendationsByDate(data?.successData);
        });
    }
    setContextValue({ screenType: screenMapping.Trip, id: id });
    getTrip(id);
  }, [id, baseUrl, screenMapping]);

  const getReccomendationsByDay = async (recommendations, daysCount) => {
    let recommendedData = {};
    for (let i = 0; i <= daysCount; i++) {
      recommendedData[i] = [];
    }
    recommendations.forEach((event) => {
      recommendedData[event.day_count].push(event);
    });
    setRecommendations(recommendedData);
  };

  const getReccomendationsByDate = async (data) => {
    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    let startDate = new Date(data?.date_start).toISOString().split("T")[0];
    let endDate = new Date(data?.date_end).toISOString().split("T")[0];
    let recommendedData = {};
    do {
      recommendedData[startDate] = [];
      const date = new Date(startDate).addDays(1);
      startDate = date.toISOString().split("T")[0];
    } while (startDate !== endDate);
    recommendedData[endDate] = [];
    recommendedData[0] = [];
    data?.recommendation_events?.forEach((event) => {
      if (
        typeof recommendedData[event.date_time ? event.date_time : 0] ===
        "object"
      ) {
        recommendedData[event.date_time ? event.date_time : 0].push(event);
      } else {
        recommendedData[event.date_time ? event.date_time : 0] = [event];
      }
    });
    const sortedData = Object.keys(recommendedData)
      .sort()
      .reduce((obj, key) => {
        obj[key] = recommendedData[key];
        return obj;
      }, {});
    setRecommendations(sortedData);
  };

  useEffect(() => {
    // Event listener to capture all click events on the landing page container
    const handleContainerClick = (event) => {
      const clickedElement = event.target;

      // Check if the clicked element is a button
      if (
        (clickedElement.tagName === "BUTTON" ||
          clickedElement.tagName === "IMG" ||
          clickedElement.role === "tab" ||
          clickedElement.tagName === "INPUT" ||
          clickedElement.outerText === "media" ||
          clickedElement.innerHTML === "follow") &&
        !isAuthenticated
      ) {
        // Display the login/signup popup
        setShowPopup(true);
      }
    };

    // Add the event listener to the landing page container
    document
      .getElementById("top-nav")
      .addEventListener("click", handleContainerClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document
        .getElementById("top-nav")
        .removeEventListener("click", handleContainerClick);
    };
  }, [isAuthenticated]);

  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-center gap-x-0.5">
          <img alt="Calendar" src={Calendar} />
          itinerary
        </div>
      ),
      addIcon: <img alt="Calendar" src={Calendar} />,
      children: recommendations && (
        <TripItinerary
          navigate={navigate}
          showEventsByDay={eventsByDay}
          recommendations={recommendations}
        />
      ),
      centered: true,
    },
    {
      key: "2",
      label: (
        <div className="flex items-center justify-center">
          <img alt="Media" src={Media} />
          media
        </div>
      ),
      addIcon: <img alt="Media" src={Media} />,
      children: "Content of Tab Pane 2",
      centered: true,
    },
  ];

  return (
    <>
      <div
        id="top-nav"
        className="md:w-[24.375rem] lg:w-[33.625rem] overflow-y-hidden"
      >
        <TopNav />
        {tripData?.pic ? (
          <Flex
            align="center"
            className="trip-image h-[16.625rem] w-full"
            style={{
              background: `url(${tripData?.pic}) center center / cover no-repeat`,
            }}
          >
            {` `}
          </Flex>
        ) : (
          <Skeleton.Image
            className="trip-image h-[16.625rem] w-full"
            active={true}
          />
        )}

        <div>
          <Flex
            vertical
            gap={38}
            className="relative z-[1] top-[-1.875rem] rounded-t-[1.875rem] px-5 pt-5 bg-offWhite"
          >
            <Details
              icon={
                <div className={`bg-purple-01 p-1.5 rounded-sm`}>
                  <img alt="Plane Icon" src={Plane} />
                </div>
              }
              privacyIcon={tripData?.privacy === "public" ? Globe : Lock}
              avatar1={Avatar1}
              avatar2={Avatar2}
              collaboraters={tripData?.collaboraters}
              title={tripData?.title}
              location={tripData?.place?.address}
              description={tripData?.description}
              type={`${tripData?.privacy} ${tripData?.type}`}
              tags={tripData?.user_tags}
              extraAvatarClass={"rounded-[20px] border-[0.156rem] border-white"}
            />
            <Tabs
              className="profile-tab"
              defaultActiveKey="1"
              activeKey="1"
              role="tab"
              items={items}
            />
          </Flex>
        </div>
        {/* <div
          className="h-[7.25rem] lg:h-[6.25rem] z-[1] relative ![background:linear-gradient(180deg,rgb(255,255,255,0)_0%,rgb(255,255,255,1)_100%]"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
          }}
        ></div> */}
        <Affix offsetBottom={-800} className="z-10 relative">
          {contextValue && (
            <ScreenContextProvider
              id={contextValue.id}
              screenType={contextValue.screenType}
            >
              <DownloadApp
                horizontalPadding={"px-[2.375rem] lg:px-[6.375rem]"}
              />
            </ScreenContextProvider>
          )}
        </Affix>
      </div>
      {contextValue && (
        <ScreenContextProvider
          id={contextValue.id}
          screenType={contextValue.screenType}
        >
          <AuthModal isModalOpen={showPopup} setIsModalOpen={setShowPopup} />
        </ScreenContextProvider>
      )}
    </>
  );
}

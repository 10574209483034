import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider, Flex, Affix } from "antd";
import ReccosListCard from "../List/ReccosListCard";
import AuthModal from "../../common/Components/AuthModal";
import "../Trip.css";
import { useSelector } from "react-redux";
import { getAuthStatus } from "../../redux/slices/authSlice";
import TripListImage from "../../assets/TripListImage.png";
import TopNav from "../../common/Components/TopNav";
import DownloadApp from "common/Components/DownloadApp";
import config from "config.js";
import ListView from "../../assets/Icons/ListView.svg";
import SearchMap from "../../assets/Icons/search-map-black.svg";
import ScreenContextProvider from "common/utils/ScreenContextProvider";

export default function OtherUserReccos() {
  const navigate = useNavigate();
  const {
    state: { userPic, username, city, id },
  } = useLocation();
  const { baseUrl, screenMapping } = config;
  const isAuthenticated = useSelector(getAuthStatus);
  const [showPopup, setShowPopup] = useState(false);
  const [contextValue, setContextValue] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [loading, setLoading] = useState(false);

  //Save context value
  useEffect(() => {
    setContextValue({ screenType: screenMapping.Profile, id: id });
  }, [id, screenMapping]);

  useEffect(() => {
    // Event listener to capture all click events on the landing page container
    const handleContainerClick = (event) => {
      const clickedElement = event.target;

      // Check if the clicked element is a button
      if (
        (clickedElement.tagName === "BUTTON" ||
          clickedElement.tagName === "IMG" ||
          clickedElement.role === "tab" ||
          clickedElement.tagName === "INPUT") &&
        !isAuthenticated
      ) {
        // Display the login/signup popup
        setShowPopup(true);
      }
    };

    // Add the event listener to the landing page container
    document
      .getElementById("top-nav")
      .addEventListener("click", handleContainerClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document
        .getElementById("top-nav")
        .removeEventListener("click", handleContainerClick);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    async function getRecommendations(userId) {
      setLoading(true);
      fetch(
        `${baseUrl}/api/v1/webapp/top_profile_recommendations?user_id=${userId}`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("profile recommendations -----", data?.successData);
          setRecommendations(data?.successData?.recommendations);
          setLoading(false);
        });
    }
    getRecommendations(id);
  }, [id, baseUrl, screenMapping]);

  return (
    <>
      <div id="top-nav" className="md:w-[24.375rem] lg:w-[33.625rem]">
        <TopNav image={TripListImage} />
        <Flex
          justify="center"
          className="py-3 shadow-[0_1px_4px_0px_rgba(0,0,0,0.25)] bg-white text-lg/[22px] font-extrabold"
        >
          reccos
        </Flex>
        <div id="scrollable-content">
          <Flex className="px-5 pt-6" align="center" gap={8} vertical>
            <img
              src={userPic}
              alt="User Profile"
              className="w-[136px] h-[136px] rounded-full"
            />
            <Flex gap={2} vertical align="center" className="text-center">
              <div className="text-[22px] font-extrabold leading-normal">
                {username}
              </div>
              <div className="">
                {`below are some of the best hotels you can visit during your
                vacation in ${city ? city : ""}.`}
              </div>
            </Flex>
          </Flex>
          <Flex
            vertical
            // gap={38}
            className="relative"
          >
            <div className="px-5">
              <Divider className="border-gray-04 mt-5 mb-5" />
            </div>
            <ReccosListCard
              extraStyles={"w-9/12"}
              navigate={navigate}
              reccos={recommendations}
              icons={[ListView, SearchMap]}
              loading={loading}
            />
          </Flex>
        </div>
        <Affix offsetBottom={-800} className="z-10 relative">
          {contextValue && (
            <ScreenContextProvider
              id={contextValue.id}
              screenType={contextValue.screenType}
            >
              <DownloadApp
                horizontalPadding={"px-[2.375rem] lg:px-[6.375rem]"}
              />
            </ScreenContextProvider>
          )}
        </Affix>
      </div>
      {contextValue && (
        <ScreenContextProvider
          id={contextValue.id}
          screenType={contextValue.screenType}
        >
          <AuthModal isModalOpen={showPopup} setIsModalOpen={setShowPopup} />
        </ScreenContextProvider>
      )}
    </>
  );
}

import React from "react";
import { Modal } from "antd";
import "../../pages/Trip.css";
import DownloadApp from "./DownloadApp";

const AuthModal = ({ isModalOpen, setIsModalOpen }) => {
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        open={isModalOpen}
        centered
        closable={false}
        width={350}
        className="auth"
        footer={null}
        onCancel={()=>setIsModalOpen(false)}
      >
        <DownloadApp
          horizontalPadding={"px-[1.125rem] rounded-2xl"}
          callback={closeModal}
        />
      </Modal>
    </>
  );
};

export default AuthModal;

import React from "react";
import { Flex, Result } from "antd";

const NotFound = () => {
  return (
    <Flex justify="center" className="h-screen" vertical>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </Flex>
  );
};

export default NotFound;

import { combineReducers } from 'redux';
import authReducer from './authSlice';

const appReducer = combineReducers({
  authReducer: authReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

import React, { useState, createContext } from "react";

export const ScreenContext = createContext();

const ScreenContextProvider = (props) => {
  const { children, id, screenType } = props;
  const [screenDetails, setScreenDetails] = useState({
    id,
    screenType,
  });

  return (
    <ScreenContext.Provider value={{ screenDetails, setScreenDetails }}>
      {children}
    </ScreenContext.Provider>
  );
};

export default ScreenContextProvider;

import React from "react";
import { Row, Col, Flex, Grid, Skeleton } from "antd";
import Search from "../../assets/Icons/Search.svg";
import ListView from "../../assets/Icons/ListView.svg";
import "../../pages/Trip.css";
import FilterList from "common/Components/FilterList";
import ReccosList from "common/Components/ReccosList";
import SomethingWentWrong from "pages/Fallback/SomethingWentWrong";

export default function ReccosListCard({
  reccos,
  navigate,
  icons,
  extraStyles,
  loading,
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <>
      <Flex vertical gap={20}>
        <div className="px-5">
          <FilterList
            placeholdertext={"search within reccos"}
            extraStyles={extraStyles}
            inputIcon={<img alt={"Search Icon"} src={Search} />}
            icons={icons ? icons : [ListView]}
          />
        </div>
        {loading ? (
          <Row className="justify-center px-2">
            <Row
              className="w-full lg:px-[2.688rem] h-fit"
              justify="flex-start"
              gutter={screens.lg ? [40, 24] : [20, 24]}
            >
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <Col span={12}>
                  <Skeleton.Image active={true} className="!w-40 !h-56" />
                </Col>
              ))}
            </Row>
          </Row>
        ) : reccos ? (
          <Row className="justify-center px-2">
            <Row
              className="w-full lg:px-[2.688rem] h-fit"
              justify="flex-start"
              gutter={screens.lg ? [40, 24] : [20, 24]}
            >
              {reccos.map((recco, index) => (
                <ReccosList
                  index={index}
                  recco={recco?.recommendation ? recco?.recommendation : recco}
                  action={
                    recco?.recommendation
                      ? recco?.recommendation?.type
                      : recco?.type
                  }
                  navigate={navigate}
                  loading={loading}
                />
              ))}
            </Row>
          </Row>
        ) : (
          <SomethingWentWrong
            buttonText={"Go Back"}
            callback={() => navigate(-1)}
          />
        )}
      </Flex>
    </>
  );
}

import React from "react";
import { Route, Routes } from "react-router";
import TripDetails from "../pages/Trip/TripDetails";
import TripList from "../pages/List/TripList";
import Reccos from "../pages/Recco/Reccos";
import Profile from "../pages/Profile/Profile";
import OtherUserReccos from "pages/Profile/OtherUserReccos";
import NotFound from "pages/Fallback/NotFound";
import BookMyReccos from "pages/Profile/BookMyReccos";

const routes = [
  {
    component: <NotFound />,
    path: "*",
  },
  {
    component: <TripDetails />,
    path: "/trip/:id",
  },
  {
    component: <TripList />,
    path: "/list/:id",
  },
  {
    component: <Reccos />,
    path: "/recco/:id",
  },
  {
    component: <Profile />,
    path: "/profile/:id",
  },
  {
    component: <OtherUserReccos />,
    path: "/profile/:id/reccos",
  },
  {
    component: <BookMyReccos />,
    path: "/profile/:id/book",
  },
];

export default function RoutesApp() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route>
        {routes.map((route, index) => (
          <Route
            key={`route-${index}`}
            element={route.component}
            path={route.path}
          />
        ))}
      </Route>
    </Routes>
  );
}

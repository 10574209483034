import React from "react";
import { Button, Flex, Result } from "antd";

const SomethingWentWrong = ({ buttonText, callback }) => {
  return (
    <Flex justify="center" vertical>
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong"
        extra={
          <Button onClick={callback} type="primary">
            {buttonText}
          </Button>
        }
      />
    </Flex>
  );
};

export default SomethingWentWrong;

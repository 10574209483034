import React from "react";
import { Row, Flex, Card, Col, Grid } from "antd";
import Search from "../../assets/Icons/Search.svg";
import Settings from "../../assets/Icons/settings.svg";
import Heart from "../../assets/Icons/heart-red.svg";
import "../../pages/Trip.css";
import Favorite from "../../assets/Icons/star.svg";
import Location from "../../assets/Icons/location-grey.svg";
import Saved from "../../assets/Icons/saved.svg";
import FilterList from "common/Components/FilterList";

export default function RecommendationsList({
  reccos,
  navigate,
  username,
  userPic,
  city,
  id,
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <>
      <Flex vertical gap={20}>
        <div className="mx-5 mt-5">
          <FilterList
            placeholdertext={"search lists"}
            extraStyles={"w-full"}
            inputIcon={<img alt={"Search Icon"} src={Search} />}
            icons={[Settings]}
          ></FilterList>
        </div>
        {reccos && (
          <Row className="justify-center">
            <Row
              className="w-full lg:px-16 px-2.5"
              justify="flex-start"
              gutter={screens.lg ? [30, 24] : [20, 24]}
            >
              {reccos.map((recco, index) => (
                <Col key={`list-card-${index}`} span={12}>
                  <Flex gap={10} align="center" vertical>
                    <Card
                      role="button"
                      className={`profile-reccos-card h-[10.375rem] w-[10.375rem]`}
                      style={{
                        background: `url(${recco?.pic}) center center ${
                          recco?.cardText ? "" : "/ cover"
                        } no-repeat`,
                      }}
                      onClick={() =>
                        navigate(
                          `/${
                            recco?.cardText
                              ? recco?.type === "bookReccos"
                                ? `profile/${id}/book`
                                : `profile/${id}/reccos`
                              : `${recco?.type}/${recco?.id}`
                          }`,
                          recco?.cardText
                            ? {
                                state: {
                                  userPic,
                                  username,
                                  city,
                                  id: id,
                                },
                              }
                            : {}
                        )
                      }
                    >
                      <Flex justify="space-between" className="h-full" vertical>
                        <Flex gap={10} vertical className="p-2.5">
                          {` `}
                          {(recco?.reccoCount > 0 ||
                            recco?.savedPlacesCount > 0) && (
                            <Flex
                              gap={5}
                              align="center"
                              className={`text-black text-[12px]/[12px] font-[590] w-fit bg-whiteBlur rounded-[13px] px-2.5 ${
                                recco?.reccoCount > 0 ? "py-1.5" : "py-1"
                              }`}
                            >
                              <img
                                src={recco?.reccoCount > 0 ? Favorite : Saved}
                                alt={
                                  recco?.reccoCount > 0 ? "Favorite" : "Saved"
                                }
                              />
                              {recco?.reccoCount > 0
                                ? recco?.reccoCount
                                : recco?.savedPlacesCount}
                            </Flex>
                          )}
                        </Flex>
                        <Flex
                          justify="space-between"
                          align="center"
                          className="w-full p-2.5 h-fit text-offWhite"
                        >
                          <div className="text-xl/[20px] font-bold">
                            {recco?.cardText}
                          </div>
                          {recco?.likes_count > 0 && (
                            <Flex gap={5} className="px-1">
                              <img src={Heart} alt="Heart" />
                              <div className="text-[10px]/[10px] font-[590]">
                                {recco?.likes_count}
                              </div>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Card>
                    <Flex gap={5} className="w-[10.375rem]" vertical>
                      <div className="text-base/[16px] font-semibold">
                        {recco?.title}
                      </div>
                      {recco?.place?.address && (
                        <Flex className="text-ellipsis text-dark-grey text-[12px]/[12px] font-[510]">
                          <img src={Location} alt="Location" />
                          <div
                            style={{
                              clear: "both",
                              display: "inline-block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {recco?.place?.address}
                          </div>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Col>
              ))}
            </Row>
          </Row>
        )}
      </Flex>
    </>
  );
}

import React, { useState, useEffect } from "react";

import { Divider, Flex, Button, Affix, Skeleton } from "antd";
import AuthModal from "../../common/Components/AuthModal";
import "../Trip.css";
import { useSelector } from "react-redux";
import { getAuthStatus } from "../../redux/slices/authSlice";
import TopNav from "../../common/Components/TopNav";
import DownloadApp from "common/Components/DownloadApp";
import Drink from "assets/Icons/glass-grey.svg";
import Bed from "assets/Icons/bed-grey.svg";
import Food from "assets/Icons/food-grey.svg";
import Cycle from "assets/Icons/cycle-grey.svg";
import Share from "assets/Icons/ShareBlack.svg";
import DownwardArrow from "assets/Icons/downward-arrow.svg";
import Favorite from "assets/Icons/favorite-white.svg";
import Saved from "assets/Icons/saved-white.svg";
import Calendar from "assets/Icons/calendar-white.svg";
import Heart from "assets/Icons/heart-grey.svg";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useGooglePlacePrice, useGooglePlaceTiming } from "../../common/hooks";
import {
  formattedTime,
  getPriceSymbol,
  getAlternativeGoogleImage,
} from "../../common/utils/functions";
import Map from "../../assets/Map.png";
import Location from "../../assets/Icons/Location.svg";
import config from "config.js";
import ScreenContextProvider from "common/utils/ScreenContextProvider";

export default function Reccos() {
  const isAuthenticated = useSelector(getAuthStatus);
  const [showPopup, setShowPopup] = useState(false);
  const [reccoData, setReccoData] = useState(null);
  const [reccoImage, setReccoImage] = useState(null);
  const { id } = useParams();
  const { priceLevel } = useGooglePlacePrice({
    placeId: reccoData?.google_place_id,
  });
  const { baseUrl, screenMapping } = config;
  const { isOpen, CloseTime, OpenTime } = useGooglePlaceTiming({
    placeId: reccoData?.google_place_id,
  });
  const [contextValue, setContextValue] = useState(null);

  const categories = {
    eat: Food,
    stay: Bed,
    do: Cycle,
    drink: Drink,
  };
  useEffect(() => {
    // Event listener to capture all click events on the landing page container
    const handleContainerClick = (event) => {
      const clickedElement = event.target;
      // Check if the clicked element is a button
      if (
        ((clickedElement.tagName === "BUTTON" &&
          clickedElement.innerText !== "book this hotel") ||
          clickedElement.tagName === "IMG" ||
          clickedElement.innerHTML === "recco" ||
          clickedElement.innerHTML === "save" ||
          clickedElement.innerHTML === "add a pro tip") &&
        !isAuthenticated
      ) {
        // Display the login/signup popup
        setShowPopup(true);
      }
    };

    // Add the event listener to the landing page container
    document
      .getElementById("top-nav")
      .addEventListener("click", handleContainerClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document
        .getElementById("top-nav")
        .removeEventListener("click", handleContainerClick);
    };
  }, [isAuthenticated]);

  // Render alternate image
  const handleImageError = async (placeId) => {
    getAlternativeGoogleImage(placeId).then(
      (res) => {
        setReccoImage(res);
      },
      (err) => {
        console.log(err);
        setReccoImage(null);
      }
    );
  };

  // Get List by list ID Api call
  useEffect(() => {
    async function getList(id) {
      fetch(
        `${baseUrl}/api/v1/webapp/get_recco_detail?place_id=${id}&user_id=30`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("api recco data -----", data?.successData);
          const reccoData = data?.successData;
          const recommendations = reccoData?.recommendations?.filter(
            (data) => data?.review && data?.review?.length > 0
          );
          reccoData?.recommendations[0]?.pic
            ? setReccoImage(reccoData?.recommendations[0]?.pic)
            : handleImageError(reccoData?.google_place_id);
          reccoData["recommendations"] = recommendations;
          setReccoData(reccoData);
        });
    }
    setContextValue({ screenType: screenMapping.PlaceDetails, id: id });
    getList(id);
  }, [id, baseUrl, screenMapping]);

  return (
    <>
      <div
        id="top-nav"
        className="md:w-[24.375rem] lg:w-[33.625rem] overflow-y-hidden"
      >
        <TopNav />
        {reccoImage ? (
          <Flex align="center" className="trip-image relative">
            <img
              src={reccoImage}
              alt="pic"
              onError={() => handleImageError(reccoData?.google_place_id)}
              className="w-screen"
            />
            {reccoImage && reccoData?.recommendations?.[0]?.user?.username && (
              <div
                className="absolute right-7 bottom-9 rounded-[5px]"
                style={{
                  background: "rgba(25, 26, 25, 0.42)",
                  backdropFilter: "blur(2px)",
                }}
              >
                <Flex
                  className="text-offWhite text-[11px]/[16px] items-end p-[5px] font-medium"
                  vertical
                >
                  <div>photo sourced by</div>
                  <Flex gap={5} align="center" className="text-[11px]/[16px]">
                    <img
                      className="w-[10px] h-[10px] rounded-full"
                      src={reccoData?.recommendations?.[0]?.user?.pic}
                      alt=""
                    />
                    {reccoData?.recommendations?.[0]?.user?.username}
                  </Flex>
                </Flex>
              </div>
            )}
          </Flex>
        ) : (
          <Skeleton.Image
            className="trip-image h-[24rem] w-full"
            active={true}
          />
        )}

        <Flex
          vertical
          className="relative z-[1] top-[-1.675rem] h-fit rounded-t-[1.875rem] bg-offWhite"
        >
          <Flex gap={10} className="pt-[1.063rem] px-[1.188rem]" vertical>
            <Flex justify="space-between" align="flex-start">
              <Flex gap={10} vertical>
                <Flex gap={10} className="h-fit">
                  {reccoData?.ispublisher?.categories
                    ?.split(",")
                    ?.map((icon) => (
                      <img
                        key={`recco-${icon}`}
                        className="p-[3px] border rounded-sm border-dark-grey"
                        src={categories[icon]}
                        alt={icon}
                      />
                    ))}
                </Flex>
                <div className="font-bold text-[1.441rem]">
                  {reccoData?.name}
                </div>
                <div className="text-dark-grey text-xs tracking-[0.03125em]">
                  {reccoData?.address}
                </div>
                {/* <Divider className="border-gray-04 mt-[25px] mb-5" /> */}
                <Flex align="center" gap={5}>
                  <div className="text-dark-grey text-xs tracking-[0.03125em]">
                    {`${reccoData?.recommendations_count} Recco${
                      reccoData?.recommendations_count > 1 ? "s" : ""
                    }`}
                  </div>
                  <span className="text-dark-grey text-xl/[20px] tracking-[0.03125em]">
                    &#183;
                  </span>
                  {priceLevel === "N/A" ? null : (
                    <div>
                      {" "}
                      <span className="px-2.5 py-0.5 rounded-[5px] text-dark-grey text-[10px]/[22px] bg-light-green tracking-[0.03125em] h-fit">
                        {getPriceSymbol(priceLevel)}
                      </span>
                    </div>
                  )}
                </Flex>
                <Flex align="center" gap={5}>
                  <div
                    className={`text-green-02 font-medium text-xs tracking-[0.03125em}]`}
                  >
                    {isOpen || isOpen === undefined ? "Open" : "Closed"}
                  </div>
                  {isOpen !== undefined && (<><span className="text-dark-grey text-xl/[20px] tracking-[0.03125em]">
                    &#183;
                  </span>
                  <div className="text-dark-grey text-xs tracking-[0.03125em]">
                    {isOpen
                      ? `Closes ${formattedTime(CloseTime)}`
                      : `Opens ${formattedTime(OpenTime)}`}
                  </div>
                  <img src={DownwardArrow} alt="DownwardArrow" /></>)}
                </Flex>
              </Flex>

              <img src={Share} alt="Share" />
            </Flex>
            {reccoData?.recommendations?.[0] && (
              <Flex gap={10} className="text-dark-grey text-[0.791rem]">
                {reccoData?.recommendations[0]?.user_tags.map((tag, index) => (
                  <div
                    key={`recco-tag-${index}`}
                    className="px-2.5 py-0.5 bg-gray-02 rounded-[0.75rem] text-[0.791rem] lowercase"
                  >
                    {tag?.tag?.title}
                  </div>
                ))}
              </Flex>
            )}

            <Flex gap={9} className="pt-[5px]">
              <Button className="hover:!text-offWhite flex gap-1.5 justify-center items-center bg-green-02 text-offWhite w-[10.688rem] md:w-2/4 h-8 rounded font-bold text-[0.916rem]/[0.916rem]">
                <img src={Favorite} alt="Favorite" />
                recco
              </Button>
              <Button className="hover:!text-offWhite flex gap-1.5 justify-center items-center bg-green-02 text-offWhite w-[10.688rem] md:w-2/4 h-8 rounded font-bold text-[0.916rem]/[0.916rem]">
                <img src={Saved} alt="Saved" />
                save
              </Button>
            </Flex>
            {reccoData?.booking_url && (
              <Button
                className="hover:!text-offWhite flex gap-2.5 justify-center items-center bg-green-02 text-offWhite h-8 rounded font-bold text-[0.916rem]/[0.916rem]"
                onClick={(e) => {
                  window.open(reccoData?.booking_url, "_blank");
                }}
              >
                <img src={Calendar} alt="Calendar" />
                book this recco
              </Button>
            )}
          </Flex>

          <div className="w-full h-[10px] my-[15px] bg-gray-02"></div>
          <Flex gap={25} vertical className="px-[19px] pt-[10px]">
            <Flex gap={15} vertical>
              <div className="text-black-text text-lg/[22px] font-extrabold lowercase">
                pro tips
              </div>
              <Flex vertical>
                {reccoData?.recommendations?.map((data, index) => (
                  <Flex key={`recco-data-${index}`} gap={10} vertical>
                    {data?.review && (
                      <>
                        <Flex gap={10}>
                          <img
                            src={data?.user?.pic}
                            className="w-[40px] h-[40px] rounded-full"
                            alt=""
                          />
                          <Flex className="text-sm w-[15.375rem]" vertical>
                            <div className="font-bold">
                              {data?.user?.username}
                            </div>
                            <div className="text-gray-text">
                              {`${
                                data?.user?.first_name
                                  ? data?.user?.first_name
                                  : ""
                              } ${
                                data?.user?.last_name
                                  ? data?.user?.last_name
                                  : ""
                              }`}
                            </div>
                          </Flex>
                          <Flex gap={5} align="center">
                            <div className="text-gray-text text-sm font-bold">
                              {data?.recommendation_events_count}
                            </div>
                            <img className="pt-1" src={Heart} alt="Heart" />
                          </Flex>
                        </Flex>
                        <Flex gap={5} vertical>
                          <div className="text-gray-text text-sm">
                            {moment(new Date(data?.created_at)).format("LL")}
                          </div>
                          <div className="text-base/[22px]">{data?.review}</div>
                        </Flex>
                        {reccoData?.recommendations?.length !== index + 1 && (
                          <Divider className="border-gray-02 border" />
                        )}
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
              <Button className="border-gray-01 rounded-[5px] w-full text-dark-grey font-bold">
                add a pro tip
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap={25} vertical className="h-fit">
          <div className="w-full h-[10px] bg-gray-02"></div>
          <Flex vertical gap={15} className="self-center w-3/4">
            <Flex justify="space-between" align="end">
              <div className="text-black-text text-lg/[22px] font-extrabold">
                location
              </div>
              <div className="text-green-02 text-[11.66px] font-semibold">
                see on map
              </div>
            </Flex>
            <Flex vertical gap={10}>
              <div className="flex gap-[5px]">
                <img alt="Location" src={Location} />
                {reccoData?.address}
              </div>
              <img alt="Map" src={Map} />
            </Flex>
          </Flex>
        </Flex>
        <Affix offsetBottom={-800} className="z-10 relative">
          {contextValue && (
            <ScreenContextProvider
              id={contextValue.id}
              screenType={contextValue.screenType}
            >
              <DownloadApp
                horizontalPadding={"px-[2.375rem] lg:px-[6.375rem]"}
              />
            </ScreenContextProvider>
          )}
        </Affix>
      </div>
      {contextValue && (
        <ScreenContextProvider
          id={contextValue.id}
          screenType={contextValue.screenType}
        >
          <AuthModal isModalOpen={showPopup} setIsModalOpen={setShowPopup} />
        </ScreenContextProvider>
      )}
    </>
  );
}

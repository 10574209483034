import React from "react";
import { Col, Flex, Card } from "antd";
import Drink from "../../assets/Icons/glass-white.svg";
import Stay from "../../assets/Icons/bed-white.svg";
import Eat from "../../assets/Icons/food-white.svg";
import AddTo from "../../assets/Icons/add-to-green.svg";
import Do from "../../assets/Icons/cycle-white.svg";
import Favorite from "../../assets/Icons/favorite.svg";
import Saved from "../../assets/Icons/saved.svg";

const ReccosList = ({ recco, index, navigate, action }) => {
  const categories = {
    eat: Eat,
    stay: Stay,
    do: Do,
    drink: Drink,
  };
  const reccoCount = recco?.place?.recommendations_count || recco?.recommendation_events_count || 1;
  return (
    <>
      <Col key={`list-card-${index}`} span={12}>
        <Card
          role="button"
          className={`reccos-card h-[14.938rem] lg:h-[16rem] shadow-[0_1px_4px_0px_rgba(0,0,0,0.25)]`}
          style={{
            background: `url(${recco?.pic}) center center / cover no-repeat`,
          }}
          onClick={() => navigate(`/recco/${recco?.place_id}`)}
        >
          <Flex justify="space-between" className="h-full" vertical>
            <Flex gap={10} vertical className="p-2.5">
              <Flex gap={5} vertical>
                <div className="pt-2.5 text-offWhite font-bold text-base/[16px]">
                  {/* Skjól tjaldsvæði */}
                  {recco?.place?.name}
                </div>
                <Flex gap={10}>
                  {recco?.categories?.split(",")?.map((icon) => (
                    <img
                      key={`category-${icon}`}
                      className="p-[3px] border rounded-sm border-offWhite"
                      src={categories[icon]}
                      alt={icon}
                    />
                  ))}
                </Flex>
              </Flex>
              <div className="p-[3px] w-fit text-[10px]/[10px] text-offWhite rounded-[3px] bg-light-gray">
                {`${reccoCount} recco${reccoCount > 1 ? "s" : ""}`}
              </div>
            </Flex>
            <Flex
              justify={action ? "space-between" : "end"}
              className="pb-[5px]"
            >
              {action && (
                <img
                  className={action === "recco" ? "p-[3px]" : "px-2.5 py-[3px]"}
                  src={action === "recco" ? Favorite : Saved}
                  alt={action === "recco" ? "Favorite" : "Saved"}
                />
              )}

              <img className="p-[3px] mr-[5px]" src={AddTo} alt="AddTo" />
            </Flex>
          </Flex>
        </Card>
      </Col>
    </>
  );
};

export default ReccosList;

import React, { useEffect } from "react";
import RoutesApp from "./router/Routes";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <div className="flex justify-center">
      <RoutesApp />
    </div>
  );
};

export default App;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import config from "config";

const root = ReactDOM.createRoot(document.getElementById("root"));
const TRACKING_ID = config.gaTrackingId;
const history = createBrowserHistory();
ReactGA.initialize(TRACKING_ID, { debug: true });
root.render(
  <Provider store={store}>
    {/* Persist Gate  */}
    <PersistGate persistor={persistor} loading={"loading persistor"}>
      <ConfigProvider>
        <Router basename="/" history={history}>
          <App />
        </Router>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

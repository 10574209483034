import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WorldMap from "../../assets/WorldMap.svg";
import SocialLink from "../../assets/Icons/social-link.svg";
import Instagram from "../../assets/Icons/instagram.svg";
import TikTok from "../../assets/Icons/tik-tok.svg";
import { Divider, Flex, Tabs, Affix, Spin, Button } from "antd";
import RecommendationsList from "./RecommendationsList";
import AuthModal from "common/Components/AuthModal";
import "../Profile.css";
import { useSelector } from "react-redux";
import { getAuthStatus } from "../../redux/slices/authSlice";
import TripListImage from "../../assets/TripListImage.png";
import CombinedRecco from "../../assets/CombinedReccos.svg";
import TopNav from "common/Components/TopNav";
import DownloadApp from "common/Components/DownloadApp";
import ArrowRight from "../../assets/Icons/arrow-right-green.svg";
import Stay from "../../assets/Icons/bed-green.svg";
import config from "config.js";
import ScreenContextProvider from "common/utils/ScreenContextProvider";

export default function TripDetails() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getAuthStatus);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [reccoData, setReccoData] = useState(null);
  const [tabActiveKey, setTabActiveKey] = useState("1");
  const { id } = useParams();
  const [contextValue, setContextValue] = useState(null);
  const { baseUrl, screenMapping } = config;

  useEffect(() => {
    // Event listener to capture all click events on the landing page container
    const handleContainerClick = (event) => {
      const clickedElement = event.target;

      // Check if the clicked element is a button
      if (
        (clickedElement.tagName === "BUTTON" ||
          clickedElement.tagName === "IMG" ||
          clickedElement.tagName === "INPUT") &&
        !isAuthenticated
      ) {
        // Display the login/signup popup
        setShowPopup(true);
      }
    };

    // Add the event listener to the landing page container
    document
      .getElementById("top-nav")
      .addEventListener("click", handleContainerClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document
        .getElementById("top-nav")
        .removeEventListener("click", handleContainerClick);
    };
  }, [isAuthenticated]);

  //Get user profile data by user id
  useEffect(() => {
    async function getList(userId) {
      fetch(`${baseUrl}/api/v1/webapp/top_profile?user_id=${userId}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("api profile data -----", data?.successData);
          setProfileData(data?.successData);
        });
    }
    setContextValue({ screenType: screenMapping.Profile, id: id });
    getList(id);
  }, [id, baseUrl, screenMapping]);

  //Get user's lists/trips by profile id
  useEffect(() => {
    async function getList(userId) {
      setLoading(true);
      const type = tabActiveKey === "2" ? "trip" : "list";
      fetch(
        `${baseUrl}/api/v1/webapp/partial_profile_data?user_id=${userId}&filter=all&type=${type}&sorting=&search=`
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(`api user's ${type}s data ----- ${data?.successData}`);
          const combinedReccos = [
            {
              type: "bookReccos",
              title: "all the reccos you can book",
              cardText: "book my reccos",
              pic: CombinedRecco,
            },
            {
              type: "listReccos",
              title: "all the places recco’ed so far",
              cardText: "reccos",
              pic: CombinedRecco,
            },
          ];
          data?.successData?.unshift(...combinedReccos);
          setReccoData(data?.successData);
          setLoading(false);
        });
    }
    getList(id);
  }, [id, baseUrl, tabActiveKey]);

  const items = [
    {
      key: "1",
      label: `lists`,
      children: loading ? (
        <Flex align="center" justify="center" className="h-60">
          <Spin size="large" />
        </Flex>
      ) : (
        <RecommendationsList
          city={profileData?.city}
          id={id}
          username={profileData?.username}
          userPic={profileData?.pic}
          navigate={navigate}
          reccos={reccoData}
        />
      ),
      centered: true,
    },
    {
      key: "2",
      label: `trips`,
      children: loading ? (
        <Flex align="center" justify="center" className="h-60">
          <Spin size="large" />
        </Flex>
      ) : (
        <RecommendationsList
          city={profileData?.city}
          id={id}
          username={profileData?.username}
          userPic={profileData?.pic}
          navigate={navigate}
          reccos={reccoData}
        />
      ),
      centered: true,
    },
  ];

  return (
    <>
      <div
        id="top-nav"
        className="md:w-[24.375rem] lg:w-[33.625rem] overflow-y-hidden"
      >
        <TopNav image={TripListImage} />
        <div className="relative">
          <img
            src={WorldMap}
            alt="World Map"
            className="profile-image w-full bg-[#aac1c7]"
          />
          <div className="absolute bottom-28 md:bottom-32 right-4 md:right-5 border-2 border-offWhite bg-green-02 text-offWhite rounded-md text-xs font-extrabold px-3">
            20 countries
          </div>
        </div>
        <div id="scrollable-content">
          <Flex
            vertical
            // gap={38}
            className="relative z-[1] top-[-5.875rem] lg:top-[-7rem] rounded-t-[1.875rem] bg-offWhite"
          >
            <img
              className="absolute left-5 top-[-34px] md:left-10 w-[99px] h-[99px] border border-white rounded-full"
              src={profileData?.pic}
              alt="Profile"
            />
            <Flex
              gap={25}
              justify="flex-end"
              className="mr-9 md:mr-10 mt-[18px]"
            >
              <Flex vertical align="center">
                <div className="text-xl font-bold">
                  {profileData?.recommendations_count}
                </div>
                <div>reccos</div>
              </Flex>
              <Flex vertical align="center">
                <div className="text-xl font-bold">
                  {profileData?.accepted_followers_count}
                </div>
                <div>followers</div>
              </Flex>
              <Flex vertical align="center">
                <div className="text-xl font-bold">
                  {profileData?.accepted_following_count}
                </div>
                <div>following</div>
              </Flex>
            </Flex>
            <div className="pt-5">
              <div className="px-5 md:px-7">
                <Flex gap={3} vertical>
                  <div className="text-[22px] font-extrabold leading-normal">
                    {profileData?.username}
                  </div>
                  <div className="text-dark-grey leading-tight">{`${profileData?.first_name} ${profileData?.last_name}`}</div>
                  <div className="text-dark-grey leading-tight">
                    {profileData?.city}
                  </div>
                </Flex>
                <Flex gap={3} vertical>
                  {profileData?.description && (
                    <div className="text-dark-grey leading-normal	pt-2.5">
                      {profileData?.description}
                    </div>
                  )}
                  <Flex
                    gap={10}
                    align="center"
                    className="text-dark-grey text-[0.791rem] pt-2.5"
                  >
                    {profileData?.user_profile_tags?.map(
                      (tag, index) =>
                        index < 3 && (
                          <div
                            key={`tag-${index}`}
                            className="px-2.5 py-0.5 bg-gray-02 rounded-[0.75rem] text-[0.791rem] capitalize"
                          >
                            {tag?.tag?.title}
                          </div>
                        )
                    )}
                  </Flex>
                </Flex>
                <Flex gap={16} vertical>
                  <Flex
                    gap={8}
                    justify="space-between"
                    className="xs:pl-2 py-1 pl-3 pr-2 lg:pr-2.5 lg:pl-4 border-[1.5px] rounded-lg border-green-02 mt-4 cursor-pointer"
                  >
                    <Flex className="lg:gap-3 gap-2">
                      <img src={Stay} alt="Stay" />
                      <Flex
                        vertical
                        className="text-green-02"
                        onClick={() =>
                          navigate(`/profile/${id}/book`, {
                            state: {
                              userPic: profileData?.pic,
                              username: profileData?.username,
                              fullName: `${profileData?.first_name} ${profileData?.last_name}`,
                              city: profileData?.city,
                              id: id,
                            },
                          })
                        }
                      >
                        <div className="text-[22px] font-extrabold leading-none">
                          book my reccos
                        </div>
                        <div>explore and book my favorite hotels</div>
                      </Flex>
                    </Flex>
                    <img
                      src={ArrowRight}
                      alt="Arrow Right"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/profile/${id}/book`, {
                          state: {
                            userPic: profileData?.pic,
                            username: profileData?.username,
                            fullName: `${profileData?.first_name} ${profileData?.last_name}`,
                            id: id,
                            city: profileData?.city,
                          },
                        });
                      }}
                    />
                  </Flex>
                  <Flex gap={10} className="flex-wrap">
                    <Button
                      type="default"
                      className="w-[7.688rem] flex-1 h-9 justify-center ![background:linear-gradient(100deg,#4229CB_17.62%,#1D59C5_50.15%,#09BB94_91.2%,#09BB94_91.2%)] rounded-[5px] items-center text-[#fffdfe] text-[0.916rem]/[0.916rem] font-extrabold"
                    >
                      follow
                    </Button>
                    <Flex gap={10} className="flex-none">
                      <img src={Instagram} alt="Instagram" />
                      <img src={TikTok} alt="TikTok" />
                      <img src={SocialLink} alt="SocialLink" />
                    </Flex>
                  </Flex>
                </Flex>
                <Divider className="border-gray-04 mt-[14px] mb-3" />
              </div>
              <Tabs
                className="profile-tab"
                defaultActiveKey={tabActiveKey}
                activeKey={tabActiveKey}
                items={items}
                onChange={(e) => setTabActiveKey(e)}
              />
            </div>
          </Flex>
        </div>
        <Affix offsetBottom={-800} className="z-10 relative mt-[-4rem]">
          {contextValue && (
            <ScreenContextProvider
              id={contextValue.id}
              screenType={contextValue.screenType}
            >
              <DownloadApp
                horizontalPadding={"px-[2.375rem] lg:px-[6.375rem]"}
              />
            </ScreenContextProvider>
          )}
        </Affix>
      </div>
      {contextValue && (
        <ScreenContextProvider
          id={contextValue.id}
          screenType={contextValue.screenType}
        >
          <AuthModal isModalOpen={showPopup} setIsModalOpen={setShowPopup} />
        </ScreenContextProvider>
      )}
    </>
  );
}

import React from "react";
import { Button, Flex, Skeleton, Grid } from "antd";
import "../../pages/Trip.css";
import Plus from "../../assets/Icons/Plus.svg";
import Location from "../../assets/Icons/Location.svg";
import Heart from "../../assets/Icons/Heart.svg";
import Map from "../../assets/Icons/Map.svg";
import ShareBlack from "../../assets/Icons/ShareBlack.svg";

const Details = ({
  icon,
  title,
  location,
  description,
  type,
  extraAvatarClass = "",
  tags,
  privacyIcon,
  collaboraters,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <>
      <Flex gap={25} vertical>
        <div className="flex justify-between">
          <div className="flex gap-[0.938rem] items-center">
            {icon}
            <div className="flex items-center gap-[0.563rem]">
              <img
                className="w-[0.688rem] h-[0.688rem]"
                alt="privacyType"
                src={privacyIcon}
              />
              {title && <span className="text-[0.854rem]">{type}</span>}
            </div>
          </div>
          <div className="flex gap-1 items-center">
            {collaboraters?.map((user, index) => (
              <div key={`collaborator-${index}`} className="flex items-center">
                {user?.user?.pic?.length > 0 && (
                  <span
                    className={`${
                      index === collaboraters?.length - 1 ||
                      collaboraters?.length === 1
                        ? ""
                        : `relative`
                    }`}
                    style={
                      collaboraters?.length !== 1
                        ? {
                            left: collaboraters?.length - (index + 1) + "rem",
                            zIndex: collaboraters?.length - (index + 1),
                          }
                        : {}
                    }
                  >
                    <img
                      alt="avatar"
                      className="w-8 h-8 rounded-full border-[0.156rem] border-white"
                      src={user?.user?.pic}
                    />
                  </span>
                )}
              </div>
            ))}

            {/* <span className={`${extraAvatarClass}`}>
                <img alt="Avatar2" src={avatar2} />
              </span> */}

            <div className="bg-white border border-gray-01 rounded-full p-px">
              <img alt="Plus Icon" src={Plus} />
            </div>
          </div>
        </div>
        {title ? (
          <Flex gap={10} vertical>
            <div className="font-bold text-[1.441rem]">{title}</div>
            <div className="flex gap-1">
              <img alt="Location Icon" src={Location} />
              <span className="text-[0.854rem] font-[510]">{location}</span>
            </div>
            <div className="text-gray-text text-[0.916rem]">{description}</div>
            <Flex
              gap={10}
              align="center"
              className="text-dark-grey text-[0.791rem]"
            >
              <>
                {tags.map(
                  (tag, index) =>
                    index < (screens.lg ? 4 : 3) && (
                      <div key={`tag-${index}`} className="px-2.5 py-0.5 bg-gray-02 rounded-[0.75rem] text-[0.791rem] capitalize">
                        {tag?.tag?.title}
                      </div>
                    )
                )}
              </>
              <>
                {tags?.length > (screens.lg ? 4 : 3) && (
                  <div className="text-[0.791rem]">{`+ ${
                    tags?.length - (screens.lg ? 4 : 3)
                  } more`}</div>
                )}
              </>
            </Flex>
          </Flex>
        ) : (
          <Skeleton active />
        )}

        <Flex justify="space-between">
          <Flex gap={18}>
            <Button
              type="default"
              className="w-[7.688rem] h-9 justify-center ![background:linear-gradient(100deg,#4229CB_17.62%,#1D59C5_50.15%,#09BB94_91.2%,#09BB94_91.2%)] rounded-[5px] items-center text-[#fffdfe] text-[0.916rem]/[0.916rem] font-extrabold"
            >
              follow
            </Button>
            <Button className="flex gap-1.5 w-[7.688rem] h-9 justify-center ![background:linear-gradient(100deg,#4229CB_17.62%,#1D59C5_50.15%,#09BB94_91.2%,#09BB94_91.2%)] rounded-[5px] items-center text-[#fffdfe] text-[0.916rem]/[0.916rem] font-extrabold">
              <img alt="Heart Icon" src={Heart} />1
            </Button>
          </Flex>
          <Flex>
            <img alt="Map Icon" src={Map} />
            <img alt="Share Icon" src={ShareBlack} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Details;

import React from "react";
import { Affix, Image, Flex } from "antd";
import "../../pages/Trip.css";
import BrandLogo from "../../assets/BrandLogo.svg";

const TopNav = () => {
  return (
    <>
      <Affix
        offsetTop={0}
        className="z-[0.125rem]"
      >
        <Flex
          justify="space-between"
          className="px-5 py-2.5 md:px-6 md:py-3 shadow-[0_1px_4px_0px_rgba(0,0,0,0.25)] bg-white"
        >
          <Image
            preview={false}
            className="w-[5.063rem] h-[2.563rem] top-[3.625rem] left-[1.188rem]"
            alt="Brand solid"
            src={BrandLogo}
          />
          <button className="!px-[0.75rem] !py-[1rem] !inline-flex ![background:linear-gradient(61deg,rgb(80,33,209,1)_-9.36%,rgb(6,110,201,1)_30.31%,rgb(8,197,140,1)_106.72%)] !top-[3.813rem] rounded-[0.313rem] items-center text-[#fffdfe] text-xs/[0.75rem] h-9 font-extrabold">
            download app
          </button>
        </Flex>
      </Affix>
    </>
  );
};

export default TopNav;

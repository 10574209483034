import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, Affix } from "antd";
import ReccosListCard from "../List/ReccosListCard";
import AuthModal from "../../common/Components/AuthModal";
import "../Trip.css";
import { useSelector } from "react-redux";
import { getAuthStatus } from "../../redux/slices/authSlice";
import TopNav from "common/Components/TopNav";
import DownloadApp from "common/Components/DownloadApp";
import config from "config.js";
import ListView from "../../assets/Icons/ListView.svg";
import SearchMap from "../../assets/Icons/search-map-black.svg";
import ScreenContextProvider from "common/utils/ScreenContextProvider";
import BookMyRecco from "../../assets/BookMyRecco.jpg";
import Location from "../../assets/Icons/Location.svg";
import Stay from "../../assets/Icons/stay-white.svg";
import Share from "../../assets/Icons/share-white.svg";

export default function BookMyReccos() {
  const navigate = useNavigate();
  const { baseUrl, lambdaBaseUrl, screenMapping } = config;
  const isAuthenticated = useSelector(getAuthStatus);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [contextValue, setContextValue] = useState(null);
  const [bookRecommendations, setBookRecommendations] = useState(null);
  const { id } = useParams();
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    async function getList(userId) {
      fetch(`${baseUrl}/api/v1/webapp/top_profile?user_id=${userId}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("api profile data -----", data?.successData);
          setProfileData(data?.successData);
        });
    }
    getList(id);
  }, [id, baseUrl]);

  //Save context value
  useEffect(() => {
    setContextValue({ screenType: screenMapping.Profile, id: id });
  }, [id, screenMapping]);

  useEffect(() => {
    // Event listener to capture all click events on the landing page container
    const handleContainerClick = (event) => {
      const clickedElement = event.target;

      // Check if the clicked element is a button
      if (
        (clickedElement.tagName === "BUTTON" ||
          clickedElement.tagName === "IMG" ||
          clickedElement.role === "tab" ||
          clickedElement.tagName === "INPUT") &&
        !isAuthenticated
      ) {
        // Display the login/signup popup
        setShowPopup(true);
      }
    };

    // Add the event listener to the landing page container
    document
      .getElementById("top-nav")
      .addEventListener("click", handleContainerClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document
        .getElementById("top-nav")
        .removeEventListener("click", handleContainerClick);
    };
  }, [isAuthenticated]);

  //Fetch booking recommendations
  useEffect(() => {
    async function getBookRecommendations(userId) {
      setLoading(true);
      fetch(`${lambdaBaseUrl}/api/get-book-my-reccos?userId=${userId}`)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log("profile book recommendation -----", data);
          setBookRecommendations(data?.book_recommendations);
          setLoading(false);
        });
    }
    getBookRecommendations(id);
  }, [id, lambdaBaseUrl]);

  return (
    <>
      <div id="top-nav" className="md:w-[24.375rem] lg:w-[33.625rem]">
        <TopNav />
        <div className="relative">
          <Flex
            justify="right"
            className="absolute w-full top-5 px-5 md:px-6"
          >
            <img src={Share} alt="Share" className="cursor-pointer" />
          </Flex>
          <img
            src={BookMyRecco}
            alt="Book My Recco"
            className="profile-image w-full bg-[#aac1c7]"
          />
          <div className="absolute bottom-12 md:my-1 md:mx-2 right-0 text-3xl text-offWhite font-extrabold px-3">
            book my reccos
          </div>
        </div>
        <div
          id="scrollable-content"
          className="relative z-[1] top-[-2.875rem] lg:top-[-3rem] bg-offWhite"
        >
          <Flex justify="space-between">
            <div className="w-48 md:w-56 relative mt-[-2.75rem]">
              <Flex
                align="center"
                className="relative left-[-0.75rem] md:left-[-0.5rem]"
                vertical
              >
                <div className="rounded-full w-[105px] h-[105px]"
                  style={{
                    backgroundImage: `url(${profileData?.pic})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: "4.5px solid #fff",
                  }}
                />
                <Flex
                  align="center"
                  gap={3}
                  justify="center"
                  className="relative top-[-1rem] bg-white w-max px-2 py-[2px] rounded shadow-shadowcard-SMALL-CARD"
                >
                  <img
                    alt="Location Icon"
                    src={Location}
                    className="w-[17px]"
                  />
                  <span className="text-[0.688rem] font-bold tracking-[0.5px]">
                    {profileData?.city}
                  </span>
                </Flex>
                <Flex align="center" className="relative top-[-10px]" vertical>
                  <div className="text-lg/[22px] font-extrabold">
                    {profileData?.username}
                  </div>
                  <div className="text-sm font-bold">{profileData?.fullName}</div>
                </Flex>
              </Flex>
            </div>
            <div className="relative">
              <div className="relative h-fit w-[175px] py-2.5 px-2 mt-8 md:mt- mr-6 md:mr-8 text-sm font-bold shadow-shadowcard-SMALL-CARD rounded-md bg-white">
                {"looking for best \nhotels? book one of \nmy favorite stays!"}
              </div>
              <div className="absolute p-2 right-8 top-3 w-fit bg-blue-02 rounded-lg">
                <img src={Stay} alt="Stay" />
              </div>
            </div>
          </Flex>
          <Flex
            vertical
            // gap={38}
            className="relative pt-3 pb-5"
          >
            <ReccosListCard
              extraStyles={"w-9/12"}
              navigate={navigate}
              loading={loading}
              reccos={bookRecommendations}
              icons={[ListView, SearchMap]}
            />
          </Flex>
        </div>
        <Affix offsetBottom={-800} className="z-10 mt-[-6.5rem] relative">
          {contextValue && (
            <ScreenContextProvider
              id={contextValue.id}
              screenType={contextValue.screenType}
            >
              <DownloadApp
                horizontalPadding={"px-[2.375rem] lg:px-[6.375rem]"}
              />
            </ScreenContextProvider>
          )}
        </Affix>
      </div>
      {contextValue && (
        <ScreenContextProvider
          id={contextValue.id}
          screenType={contextValue.screenType}
        >
          <AuthModal isModalOpen={showPopup} setIsModalOpen={setShowPopup} />
        </ScreenContextProvider>
      )}
    </>
  );
}

import { useState, useEffect } from "react";

const useGooglePlaceTiming = ({ placeId }) => {
  const [isOpen, setIsOpen] = useState(null);
  const [OpenTime, setOpenTime] = useState(null);
  const [CloseTime, setCloseTime] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails(
        {
          placeId: placeId,
          fields: ["opening_hours"],
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setIsOpen(place?.opening_hours?.open_now);
            setOpenTime(place?.opening_hours?.periods[0]?.open?.time);
            setCloseTime(place?.opening_hours?.periods[0]?.close?.time);
          } else {
            console.error("Error fetching place details:", status);
          }
        }
      );
    };

    if (placeId && window.google) {
      fetchData();
    }
  }, [placeId]);

  return {
    isOpen,
    OpenTime,
    CloseTime,
  };
};

export default useGooglePlaceTiming;

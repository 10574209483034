const config = {
  lambdaBaseUrl: process.env.REACT_APP_LAMBDA_BASE_URL,
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  androidHost: process.env.REACT_APP_ANDROID_HOST,
  androidScheme: process.env.REACT_APP_ANDROID_SCHEME,
  appPackage: process.env.REACT_APP_ANDROID_APP_PACKAGE,
  urlScheme: process.env.REACT_APP_IOS_URL_SCHEME,
  gaTrackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  //screen mapping for mobile app navigation
  screenMapping: {
    FeedListView: "lt", // 'lt' type maps to 'FeedListView'
    Profile: "pv", // 'pv' type maps to 'Profile'
    OtherProfile: "op", // 'op' type to 'Otherprofile'
    Trip: "tp", // 'tp' to 'Trip'
    PlaceDetails: "rc", // rc to "PlaceDetails"
  },
};

export default config;
